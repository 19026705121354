/*
 * API 统一管理。使用时按需导入。
 * 团队开发可以创建多个模块分别引入axioms index.us. 管理自己的接口。
 * 使用：
 * 组件内，导入api.js 
 * import {apiAddress} from “./api.js”
 * 
 */
import qs from 'qs'; // qs模块，用来序列化post类型的数据
import { get, post, request, download } from './http'

//移动端预约
export const competition = {
    //发送验证码
    sendVerification(params) {
        return post('api/prize/send/verification_code', params);
    },
    //对比验证码
    contrastVerification(params) {
        return post('api/prize/compare/verification_code', params);
    },
    //奖项赛道查询
    info(params) {
        return post('api/prize/sort/info', params);
    },
    //奖项信息查询
    select(params) {
        return post('api/prize_info/select', params);
    },
    //查询填写收款信息
    payments(params) {
        return post('api/prize_receipt_info/select', params);
    },
    //奖项收款信息保存
    save(params) {
        return post('api/prize_receipt_info/save', params);
    },
}